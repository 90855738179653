// extracted by mini-css-extract-plugin
export var box = "about-module--box--Y2-yS";
export var boxText = "about-module--boxText--X1yUE";
export var boxTitle = "about-module--boxTitle--VwvKf";
export var boxes = "about-module--boxes--8YdVe";
export var container = "about-module--container--MHyV7";
export var headerRow = "about-module--headerRow--gpnCs";
export var iconWrapper = "about-module--iconWrapper--JunlS";
export var missions = "about-module--missions--gNFu7";
export var outterContainer = "about-module--outterContainer--klZ1s";
export var right = "about-module--right---iblg";
export var sectionUsers = "about-module--sectionUsers--HJ5dK";
export var sectionValue = "about-module--sectionValue--tbklb";
export var techBoxes = "about-module--techBoxes--7W37p";
export var technology = "about-module--technology--EsLzZ";
export var text = "about-module--text--Z8mmM";
export var title = "about-module--title--2h5ck";