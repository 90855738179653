import React from "react"
import Layout from '../components/Layout'
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import CallToAction from '../components/CallToAction';
import { conversionId, googleConversion } from '../utils'

import floatingIslandImg from '../assets/images/floating-island.png';
import goodsImg from '../assets/images/goods.png';
import immerseImg from '../assets/images/immerse.png';
import laptopImg from '../assets/images/laptop.png';
import verifyImg from '../assets/images/verify.png';
import MissionIcon from '../assets/svg/mission_icon.svg';
import ValuesIcon from '../assets/svg/values_icon.svg';
import VisionIcon from '../assets/svg/vision_icon.svg';
import TechIcon from '../assets/svg/tech_icon.svg';
import CreatorIcon from '../assets/svg/creator_icon.svg';
import ConsumerIcon from '../assets/svg/consumer_icon.svg';


// import blockImg from '../assets/images/block.png';
// import dragAndDropImg from '../assets/images/drag-and-drop.png';

import * as styles from "./about.module.scss"
import './about.scss'

const missions = [
  {
    id: "mission",
    icon: <MissionIcon />,
    title: "Our Mission",
    text: "Double is the future of commerce and community, designed and optimized for the Metaverse that allows anyone and everyone to become a creator.",
  },
  {
    id: "values",
    icon: <ValuesIcon />,
    title: "Our Values",
    text: "We believe in true Decentralization. We want to level the playing field by making the tools for Metaverse creation freely available and accessible to all.",
  },
  {
    id: "vision",
    icon: <VisionIcon />,
    title: "Our Vision",
    text: "We want everyone to build and experience the Metaverse. We see a future where anyone can buy and sell in the Metaverse, build exhibition spaces, galleries, community hubs and experiences, all without coding.",
  },
]

const techs = [
  {
    title: "Metaverse-native experiences",
    text: "Our builder tools leverage increasing capabilities offered by rich and immersive environments like VR goggles and AR smart glasses, and elevate the overall user experience."
  },
  {
    title: "No code for all",
    text: "Metaverse builder tools have typically catered to software developers, but Metaverse commerce and true mass adoption demands no-code and accessible creation tools."
  },
  {
    title: "Web3 support",
    text: "A successful Metaverse commerce ecosystem needs a platform that can seamlessly support physical products, digital goods and NFTs, as well as other Web3-native artifacts."
  },
  {
    title: "Workflow and integrations",
    text: "Many merchants have already set up stores on Amazon, Shopify, WooCommerce or similar platforms but want to sell in the Metaverse without duplicating workflows."
  }
]

const users = [
  {
    icon: <CreatorIcon className={styles.abc} />,
    title: "For Creators and Sellers",
    text: "Build a store, gallery, virtual world or social media hub and establish your presence in the Metaverse. Double empowers you to reach new customers and audiences, monetize your work and establish your reputation as an innovator and early adopter."
  },
  {
    icon: <ConsumerIcon />,
    title: "For Consumers",
    text: "Whether you’re shopping in a Metaverse store or visiting a virtual gallery, Double ensures a seamless user experience and access to immersive content and experiences that are always new and exciting."
  }
]

const Box = ({id, icon, title, text}) => (
  <div className={styles.box} id={id && id}>
    <div className={styles.iconWrapper}>
      {icon && icon}
    </div>
    <h3 className={styles.boxTitle}>{title}</h3>
    <p className={styles.boxText}>{text}</p>
  </div>
)

const about = () => (
  <Layout
    title="Double | About"
    pageClassName="page-about"
  >
    {/* HERO */}
    <section className="page-about__section-hero">
      <div className="page-about__section-hero__container">
        <div className="page-about__section-hero__container__content">
          <h1 className="page-title">About Double</h1>
          <p className="page-title-tag">Empowering anyone and everyone to create their own Metaverse space.</p>
        </div>
        <div className="page-about__section-hero__container__image">
          <img src={floatingIslandImg} alt="" />
        </div>
      </div>
    </section>
    {/* Mission */}
    <section className={styles.sectionValue}>
      <div className={styles.container}>
        <div className={styles.missions}>
          {missions.map(mission => (
            <Box
              id={mission.id}
              key={mission.title}
              icon={mission.icon}
              title={mission.title}
              text={mission.text}            
            />
          ))}
        </div>
        <div className={styles.technology} id="tech">
          <div className={styles.headerRow}>
            <div className={styles.iconWrapper}>
              <TechIcon />
            </div>
            <div className={styles.right}>
              <h2 className={styles.title}>Our Technology</h2>
              <p className={styles.text}>There are 4 urgent technical gaps in Metaverse commerce that Double directly addresses:</p>
            </div>
          </div>
          <div className={styles.techBoxes}>
            {techs.map(tech => (
              <Box
                key={tech.title}
                title={tech.title}
                text={tech.text}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
    <section className={styles.sectionUsers}>
      <div className={styles.outterContainer}>
        <div className={styles.container}>
          <div className={styles.boxes}>
            {users.map(user => (
              <Box
                icon={user.icon}
                title={user.title}
                text={user.text}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
    <CallToAction callBack={() => googleConversion(conversionId.getStarted)} />
  </Layout>
)

export default about;