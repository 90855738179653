import { pageLink } from '../../utils'
import * as styles from "./index.module.scss"

import React from 'react'

const index = ({ callback }) => {
  return (
    <section className={styles.callToAction}>
      <div className={styles.callToAction__container}>
        <h1 className={styles.title}>Everything you need to create your own Metaverse space</h1>
        <p className={styles.subTitle}>Build in a matter of minutes, no coding required.</p>
        <a
          className={`${styles.button} button button-100`}
          href={pageLink.store}
          target="_blank"
          rel="noreferrer"
          aria-label="get started"
          onClick={callback}
        >
          get started
        </a>
      </div>
    </section>
  )
}

export default index
